<template>
  <div class="d-flex empty gap-0-5">
    <img alt="Empty" src="/images/emptys/empty-list.svg" />
    <p>No se encontraron {{ notFound }}</p>
  </div>
</template>

<script>
export default {
  props: {
    notFound: {
      type: String,
      required: true,
    },
  },
};
</script>
